import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { ModalProvider } from './ModalContext';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { SettingsProvider } from './Telegram UI/SettingsContext';
import { createAppKit } from '@reown/appkit/react'
import { arbitrum, bsc } from '@reown/appkit/networks'
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5'

const root = ReactDOM.createRoot(document.getElementById('root'));
const MANIFEST_URL = `${window.location.origin}/tonconnect-manifest.json`;


const projectId = '2aca272d18deb10ff748260da5f78bfd';
const metadata = {
  name: 'Skilleareum',
  description: 'Skilleareum Ecosystem',
  url: 'https://skilleareum.ai/',
  icons: ['https://skilleareum.ai/favicon.png']
};

createAppKit({
  adapters: [new Ethers5Adapter()],
  networks: [bsc, arbitrum],
  metadata,
  projectId,
  features: {
    analytics: true,
  }
})

root.render(
  <React.StrictMode>
    <BrowserRouter>

      <Provider store={store} >
        <TonConnectUIProvider manifestUrl={MANIFEST_URL}>
          <SettingsProvider>
            <App />
          </SettingsProvider>
        </TonConnectUIProvider>
      </Provider>

    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
